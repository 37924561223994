import { GET_PAGE } from "~/graphql";

type PageResponse = {
  pages: Page[];
};

export function useFetchPages() {
  const page = ref<Page>();

  const fetchPages = async (slug: string) => {
    const { data } = await useAsyncQuery<PageResponse>(GET_PAGE, { slug });

    if (data.value) {
      page.value = data.value.pages[0];
    }
  };

  return {
    page,
    fetchPages,
  };
}
