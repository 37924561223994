<template>
  <section ref="element" id="manifesto" class="full-screen">
    <div class="container content">
      <span class="highlight">{{ title }}</span>
      <span class="split-text">{{ content }}</span>
    </div>
  </section>
</template>

<script setup lang="ts">
import SplitType from "split-type";

const { $gsap: gsap } = useNuxtApp();
const element = ref();
type ManifestoProps = {
  title: string,
  content: string;
};

const props = defineProps<ManifestoProps>();

onMounted(() => {
  const text = new SplitType(".split-text", { types: 'words,chars' });

  gsap.timeline({
    scrollTrigger: {
      trigger: element.value,
      start: "top center",
      end: "bottom center",
      scrub: 1,
    },
  }).fromTo(".split-text .char", {
    color: "#364042", // Color oscuro inicial
  }, {
    color: "#fff", // Color claro al hacer scroll
    stagger: 0.05,
  });
});
</script>

<style scoped lang="scss">
.full-screen {
  @apply w-full flex justify-center items-center bg-gray-100 py-16 lg:py-32;
}

.content {
  @apply text-left font-extralight leading-tight text-white text-lg md:text-2xl lg:text-5xl;
}

.highlight {
  @apply font-medium text-xs md:text-base lg:text-lg text-orange-giants pr-8;
}
</style>
